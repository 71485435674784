$(document).ready(()=>{

    $('#rev_slider_1059_1').show().revolution({
        sliderType:"standard",
        jsFileLocation:"plugins/rs/js/",
        sliderLayout:"auto",
        dottedOverlay:"none",
        delay:9000,
        responsiveLevels:[1240,1024,778,480],
        visibilityLevels:[1400,1024,778,480],
        gridwidth:[1400,1240,778,480],
        navigation: {
            keyboardNavigation:"off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation:"off",
            mouseScrollReverse:"default",
            onHoverStop:"off",
            touch:{
                touchenabled:"on",
                swipe_threshold: 75,
                swipe_min_touches: 50,
                swipe_direction: "horizontal",
                drag_block_vertical: false
            }
            ,
            bullets: {
                enable:true,
                hide_onmobile:true,
                hide_under:800,
                style:"zeus",
                hide_onleave:false,
                direction:"horizontal",
                h_align:"center",
                v_align:"bottom",
                h_offset:0,
                v_offset:30,
                space:5,
                tmp:'<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title">{{title}}</span>'
            }
        },
        parallax: {
            type:"mouse+scroll",
            origo:"slidercenter",
            speed:2000,
            levels:[1,2,3,20,25,30,35,40,45,50,46,47,48,49,50,55],
            disable_onmobile:"on"
        },
    });

    var wi = $(window).width();
    if (wi <= 480){
       $("#imageRS").attr('src', 'images/oneslider/rs-sm.jpg');
    }


    AOS.init({
        // Global settings:
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        once: false, // whether animation should happen only once - while scrolling down
        mirror: true, // whether elements should animate out while scrolling past them

    });

    const scenes = [];
    const scenesSelector = document.querySelectorAll('.scene');


    for(let i=0; i<scenesSelector.length; i++){
        scenes[i] = new Parallax(scenesSelector[i],{
            relativeInput: true
        });
    }

});